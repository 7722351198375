import { Spinner } from 'boards-web-ui'
import { useAcademyPage } from '@features/academy/hooks'
import styles from './AcademyPage.module.css'

export const AcademyPage = () => {
  const { iframeSrc, handleIframeLoad, iframeKeyHash, isLoaded } =
    useAcademyPage()

  return (
    <div className={styles.Root}>
      <Spinner className={styles.Spinner} size="100px" spinning={!isLoaded}>
        <iframe
          key={iframeKeyHash}
          title="Academy"
          src={iframeSrc || ''}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="fullscreen"
          onLoad={handleIframeLoad}
        />
      </Spinner>
    </div>
  )
}
